import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ApiResponse } from '@utils/models/ApiResponse';
import { map } from 'rxjs/operators';
import { SearchControlContenedoresDto } from '~models/carga/dto/SearchControlContenedoresDto';
import { UpdateScaRoutingContenedorDto } from '~models/carga/dto/UpdateScaRoutingContenedorDto';
import { ScaRoutingControlContenedorListadoProjection } from '~models/carga/projection';
import { Observable } from 'rxjs';
import { ScaRoutingContenedorDto } from '~models/carga/interface/ScaRoutingContenedor';
import { SearchParameter } from 'app/modules/gestion/common/components/filtro-por-referencia/filtro-por-referencia.service';

@Injectable({
	providedIn: 'root',
})
export class ScaRoutingContenedorService {
	private readonly _url = `${environment.HOST_CARGA}/scaroutingcontenedores`;
	//private readonly urlTmp = `http://localhost:8092/sumax-erp-backend-carga/api/scaroutingcontenedores`;

	constructor(private http: HttpClient) {}

	updateCustom(scaRoutingContenedores: UpdateScaRoutingContenedorDto): Observable<ApiResponse<void>> {
		return this.http.put<ApiResponse<void>>(`${this._url}/updateCustom`, scaRoutingContenedores);
	}

	findById(idContenedor: number): Observable<ScaRoutingContenedorDto> {
		return this.http.get<ApiResponse<ScaRoutingContenedorDto>>(`${this._url}/model-se-ro/${idContenedor}`).pipe(map((res) => res.data));
	}

	findAllByCustomDto(searchControlContenedor: SearchControlContenedoresDto | SearchParameter): Observable<ScaRoutingControlContenedorListadoProjection[]> {
		return this.http.post<ApiResponse<ScaRoutingControlContenedorListadoProjection[]>>(`${this._url}/model-se-ro/findAllByCustomDto`, searchControlContenedor).pipe(map((res) => res.data));
	}

	countByIdRouting(iRouting: number): Observable<number> {
		return this.http.get<ApiResponse<number>>(`${this._url}/countByIdRouting/${iRouting}`).pipe(map((res) => res.data));
	}
}
