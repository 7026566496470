import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

import { NgSelectOption } from '@utils/interface/ngselect.interfaces';
import { ApiResponse } from '@utils/models/ApiResponse';

import { ScaTipoEstadoRouting } from '~models/carga/interface/ScaTipoEstadoRouting';

interface TipoEstadoRoutingService {
	findAllByEstado(state: boolean): Observable<Required<ScaTipoEstadoRouting>[]>;
	getSelectList(): Observable<NgSelectOption<Required<ScaTipoEstadoRouting>>[]>;
}

@Injectable({
	providedIn: 'root',
})
export class ScaTipoEstadoRoutingService implements TipoEstadoRoutingService {
	private readonly _url = `${environment.HOST_MAESTROS_SYNC}/scatipoestadoroutinges`;
	private _dataSelect$?: Observable<NgSelectOption<Required<ScaTipoEstadoRouting>>[]>;

	constructor(private readonly _http: HttpClient) {}

	findAllByEstado(state = true): Observable<Required<ScaTipoEstadoRouting>[]> {
		return this._http.get<ApiResponse<Required<ScaTipoEstadoRouting>[]>>(`${this._url}/findAllByEstado/${String(state)}`).pipe(map((res) => res.data));
	}

	getSelectList(saveInMemory = true): Observable<NgSelectOption<Required<ScaTipoEstadoRouting>>[]> {
		if (!saveInMemory) this._dataSelect$ = undefined;

		if (!this._dataSelect$)
			this._dataSelect$ = this.findAllByEstado().pipe(
				map((data) => {
					return data.map((item) => {
						return {
							...item,
							value: item.idTipoEstadoRouting,
							label: `${item.codigo} - ${item.nombre}`,
						};
					});
				}),
				shareReplay(1)
			);

		return this._dataSelect$;
	}

	getSelect(e: ScaTipoEstadoRouting | null): NgSelectOption<ScaTipoEstadoRouting> | null {
		return e
			? {
					...e,
					value: e.idTipoEstadoRouting,
					label: `${e.codigo} - ${e.nombre}`,
			  }
			: null;
	}
}
