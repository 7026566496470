import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';

import { ApiResponse } from '@utils/models/ApiResponse';

import { ScaSolicitudTransbordoDto } from '~models/carga/interface/ScaSolicitudTransbordo';
import { ScaSolicitudTransbordoProjection } from '~models/carga/projection/ScaSolicitudTransbordoProjection';
import { SearchScaSolicitudTransbordoCustomDto } from '~models/carga/dto/SearchScaSolicitudTransbordoCustomDto';
import { GenericService } from '@utils/classes/GenericService';
import { ConfigService } from '@utils/interface/service.interface';

// eslint-disable-next-line @typescript-eslint/naming-convention
const URL = `${environment.HOST_CARGA}/scasolicitudtransbordos`;

interface ScaSolicitudTransbordo {
	findAllCustomDto(search: SearchScaSolicitudTransbordoCustomDto): Observable<ScaSolicitudTransbordoProjection[]>;
}

@Injectable({
	providedIn: 'root',
})
export class ScaSolicitudTransbordoService extends GenericService<ScaSolicitudTransbordoDto, number> implements ScaSolicitudTransbordo {
	constructor(protected readonly _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: URL,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllCustomDto(search: SearchScaSolicitudTransbordoCustomDto): Observable<ScaSolicitudTransbordoProjection[]> {
		return this._http.post<ApiResponse<ScaSolicitudTransbordoProjection[]>>(`${URL}/model-se-ro/findAllCustomDto`, search).pipe(map((res) => res.data));
	}
}
