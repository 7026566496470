import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

import { ApiResponse } from '@utils/models/ApiResponse';
import { NgSelectOption } from '@utils/interface/ngselect.interfaces';
import { ScaCondicion } from '~models/carga/interface/ScaCondicion';
import { GenericService } from '../../../../@utils/classes/GenericService';
import { ConfigService } from '@utils/interface/service.interface';

@Injectable({
	providedIn: 'root',
})
export class ScaCondicionService extends GenericService<ScaCondicion, number> {
	private readonly _url = `${environment.HOST_CARGA}/scacondiciones`;
	private _dataSelect$?: Observable<NgSelectOption<ScaCondicion>[]>;

	constructor(protected readonly _http: HttpClient) {
		super(_http);
	}
	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}
	findAllByEstado(estado: boolean): Observable<ScaCondicion[]> {
		return this._http.get<ApiResponse<ScaCondicion[]>>(`${this._url}/model-se-ro/findAllByEstado/${String(estado)}`).pipe(
			map((res) => res.data),
			shareReplay(1)
		);
	}

	getSelectList(estado: boolean, saveInMemory = true): Observable<NgSelectOption<ScaCondicion>[]> {
		if (!saveInMemory) this._dataSelect$ = undefined;

		if (!this._dataSelect$)
			this._dataSelect$ = this.findAllByEstado(estado).pipe(
				map((data) =>
					data.map((item) => ({
						...item,
						value: item.idCondicion,
						label: `${item.codigo} - ${item.nombre}`,
					}))
				),
				shareReplay(1)
			);

		return this._dataSelect$;
	}

	/**
	 * ! deprecated
	 * @deprecated no utilzar
	 */
	getSelect(item: ScaCondicion | null): NgSelectOption<ScaCondicion> | null {
		return item
			? {
					...item,
					value: item.idCondicion,
					label: `${item.codigo} - ${item.nombre}`,
			  }
			: null;
	}
}
