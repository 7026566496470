import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';

import { ApiResponse } from '@utils/models/ApiResponse';

@Injectable({
	providedIn: 'root',
})
export class ScaRoutingDetalleService {
	private readonly _url = `${environment.HOST_CARGA}/scaroutingdetalles`;

	constructor(private http: HttpClient) {}

	findAllByIdRouting(idRouting: number) {
		return this.http.get<ApiResponse>(`${this._url}/model-se-ro/findAllByIdRouting/${idRouting}`).pipe(map((res) => res.data));
	}
}
