import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { NgSelectOption } from '@utils/interface/ngselect.interfaces';
import { ApiResponse } from '@utils/models/ApiResponse';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TbUnidadMedida } from '~models/maestros/interface/TbUnidadMedida';
import { HttpClient } from '@angular/common/http';
import { Scaconfiguracionunidadmedida } from '~models/maestros/interface/Scaconfiguracionunidadmedida';

type MemoType = Observable<NgSelectOption<Required<TbUnidadMedida>>[]>;
type Memo = {
	[type: string]: MemoType | null;
};

@Injectable({
	providedIn: 'root',
})
export class ScaConfiguracionUnidadMedidaService {
	private readonly _url = `${environment.HOST_MAESTROS_SYNC}/scaconfiguracionunidadmedidas`;

	private _dataSelect: Memo = {};

	constructor(private _http: HttpClient) {}

	findAllCustom(codigo: string): Observable<Required<TbUnidadMedida>[]> {
		return this._http.get<ApiResponse<Scaconfiguracionunidadmedida[]>>(`${this._url}/findAllCustom/${codigo}`).pipe(map(({ data }) => data.map((d) => d.tbUnidadMedida)));
	}

	getSelectList(codigo: string, saveInMemory = true): Observable<NgSelectOption<Required<TbUnidadMedida>>[]> {
		const fillNgSelect = (): void => {
			this._dataSelect[codigo] = this.findAllCustom(codigo).pipe(
				map((data) =>
					data.map((item) => ({
						...item,
						value: item.idUnidadMedida,
						label: `${item.codigo} - ${item.nombre}`,
					}))
				),
				shareReplay(1)
			);
		};

		if (this._dataSelect.hasOwnProperty(codigo)) {
			if (!saveInMemory) this._dataSelect[codigo] = null;

			if (!this._dataSelect[codigo]) fillNgSelect();

			return <MemoType>this._dataSelect[codigo];
		}

		fillNgSelect();

		return <MemoType>this._dataSelect[codigo];
	}

	/**
	 *
	 * @deprecated no utilizar
	 */
	getSelect(e: TbUnidadMedida | null): NgSelectOption<TbUnidadMedida> | null {
		return e
			? {
					...e,
					value: e.idUnidadMedida,
					label: `${e.codigo ?? ''} - ${e.nombre ?? ''}`,
			  }
			: null;
	}
}
