/* eslint-disable @typescript-eslint/no-unsafe-return */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from 'environments/environment';

import { ApiResponse } from '@utils/models/ApiResponse';
import { ConfigService } from '@utils/interface/service.interface';
import { GenericService } from '@utils/classes/GenericService';

import { ScaRoutingConcepto } from '~models/carga/interface/ScaRoutingConcepto';
import { ScaRoutingLite } from '~models/carga/interface/ScaRouting';
import { UtilidadTotalDetalle } from '~models/carga/projection/UtilidadTotalDetalle';
export interface ListUtilidadMonedaIndividualView {
	listUtilidadMonedaIndividualView: Detalle[];
}
export interface Detalle {
	idMoneda: number;
	moneda: string;
	costo: number;
	vtaAgente: number;
	venta: number;
	utilidad: number;
}

@Injectable({
	providedIn: 'root',
})
export class ScaRoutingConceptoService extends GenericService<ScaRoutingConcepto, number> {
	private readonly _url = `${environment.HOST_CARGA}/scaroutingconceptos`;
	routingConceptoCambio: Subject<ListUtilidadMonedaIndividualView> = new Subject<ListUtilidadMonedaIndividualView>();

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	scaRoutingConcepto: BehaviorSubject<ScaRoutingConcepto> = new BehaviorSubject<ScaRoutingConcepto>(<ScaRoutingConcepto>{});
	getroutingConceptoCambio(): Observable<ListUtilidadMonedaIndividualView> {
		return this.routingConceptoCambio.asObservable();
	}

	constructor(private http: HttpClient) {
		super(http);
	}

	findById(idRoutingConcepto: number): Observable<ScaRoutingConcepto> {
		return this._http.get<ApiResponse<ScaRoutingConcepto>>(`${this._url}/model-se-ro/${idRoutingConcepto}`).pipe(map((res) => res.data));
	}

	findAllByIdRouting(idRouting: number): Observable<ScaRoutingConcepto[]> {
		return this.http.get<ApiResponse<ScaRoutingConcepto[]>>(`${this._url}/model-se-ro/findAllByIdRouting/${idRouting}`).pipe(map((res) => res.data));
	}

	findAllUtilidadTotal(idRouting: number): Observable<UtilidadTotalDetalle[]> {
		return this.http.get<ApiResponse<UtilidadTotalDetalle[]>>(`${this._url}/model-se-ro/findAllUtilidadTotal/${idRouting}`).pipe(map((res) => res.data));
	}

	updateAllCustom(scaRouting: ScaRoutingLite, scaRoutingConcepto: ScaRoutingConcepto[]): Observable<ApiResponse<void>> {
		return this.http.post<ApiResponse<void>>(`${this._url}/updateAllCustom`, { scaRouting: scaRouting, scaRoutingConcepto: scaRoutingConcepto });
	}
}
