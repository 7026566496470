import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from '@utils/classes/GenericService';
import { ConfigService } from '@utils/interface/service.interface';
import { ApiResponse } from '@utils/models/ApiResponse';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScaRoutingEstadoCustom } from '~models/carga/interface';

@Injectable({
	providedIn: 'root',
})
export class ScaRoutingEstadoService extends GenericService<ScaRoutingEstadoCustom, number> {
	private readonly _url = `${environment.HOST_CARGA}/scaroutingestados`;

	constructor(private http: HttpClient) {
		super(http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByIdRouting(idRouting: number): Observable<ScaRoutingEstadoCustom[]> {
		return this._http.get<ApiResponse<ScaRoutingEstadoCustom[]>>(`${this._url}/model-se-ro/findAllByIdRouting/${idRouting}`).pipe(map((resp) => resp.data));
	}
	updateEstadoForScaRouting(scaRoutingEstado: ScaRoutingEstadoCustom): Observable<ScaRoutingEstadoCustom[]> {
		return this._http.post<ApiResponse<ScaRoutingEstadoCustom[]>>(`${this._url}/updateEstadoForScaRouting`, scaRoutingEstado).pipe(map((res) => res.data));
	}
}
