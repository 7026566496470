import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { ScaOrdenCargaTransbordoDto } from '../../../shared/models/carga/interface/ScaOrdenCargaTransbordo';

@Injectable({
	providedIn: 'root',
})
export class ScaOrdenCargaTransbordoService {
	public transbordos = new Subject<ScaOrdenCargaTransbordoDto[]>();

	getScaOrdenCargaTransbordo(scaOrdenCargaTransbordos: ScaOrdenCargaTransbordoDto[]): void {
		this.transbordos.next([...scaOrdenCargaTransbordos]);
	}
}
