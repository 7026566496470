/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, forkJoin, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { ApiResponse } from '@utils/models/ApiResponse';
import { ConfigService } from '@utils/interface/service.interface';
import { DateUtil } from '@utils/classes/DateUtil';
import { GenericService } from '@utils/classes/GenericService';
import { EnumTipoCalculoPlazo } from '~models/carga/Util/enums/EnumTipoCalculoPlazo';
import { EnumTipoFecha } from '~models/Util/EnumTipoFecha';
import { ScaIncidenciaConfiguracion } from '~models/carga/interface/ScaIncidenciaConfiguracion';
import { ScaIncidenciaConfiguracionCustomDto } from '~models/carga/dto/ScaIncidenciaConfiguracionCustomDto';
import { ScaIncidenciaConfiguracionService } from './scaIncidenciaConfiguracion.service';
import { ScaRoutingIncidencia } from '~models/carga/interface/ScaRoutingIncidencia';
import { ScaRoutingIncidenciaCustomDto } from '~models/carga/dto/ScaRoutingIncidenciaCustomDto';
import { ScaRoutingIncidenciaListadoProjection } from '~models/carga/projection/ScaRoutingIncidenciaListadoProjection';
import { ScaRoutingProjection } from '~models/carga/projection/ScaRoutingProjection';
import { orderBy } from 'lodash';
import { DateFormat } from '~models/Util';

const HOLIDAYS_TEMP = [
	// 2020
	{ date: new Date('2020-01-01 00:00:00'), name: 'Año Nuevo' },
	{ date: new Date('2020-04-09 00:00:00'), name: 'Jueves Santo' },
	{ date: new Date('2020-04-10 00:00:00'), name: 'Viernes Santo' },
	{ date: new Date('2020-04-12 00:00:00'), name: 'Pascua' },
	{ date: new Date('2020-05-01 00:00:00'), name: 'Fiesta del trabajo' },
	{ date: new Date('2020-06-29 00:00:00'), name: 'San Pedro y San Pablo' },
	{ date: new Date('2020-07-28 00:00:00'), name: 'Día de la Independencia' },
	{ date: new Date('2020-07-29 00:00:00'), name: 'Día de la Independencia' },
	{ date: new Date('2020-08-30 00:00:00'), name: 'Día de Santa Rosa de Lima' },
	{ date: new Date('2020-10-08 00:00:00'), name: 'Combate de Angamos' },
	{ date: new Date('2020-11-01 00:00:00'), name: 'Todos los Santos' },
	{ date: new Date('2020-12-08 00:00:00'), name: 'La inmaculada concepción' },
	{ date: new Date('2020-12-25 00:00:00'), name: 'Navidad' },
	// 2021
	{ date: new Date('2021-01-01 00:00:00'), name: 'Año Nuevo' },
	{ date: new Date('2021-04-01 00:00:00'), name: 'Jueves Santo' },
	{ date: new Date('2021-04-02 00:00:00'), name: 'Viernes Santo' },
	{ date: new Date('2021-04-04 00:00:00'), name: 'Pascua' },
	{ date: new Date('2021-05-01 00:00:00'), name: 'Fiesta del trabajo' },
	{ date: new Date('2021-06-29 00:00:00'), name: 'San Pedro y San Pablo' },
	{ date: new Date('2021-07-28 00:00:00'), name: 'Día de la Independencia' },
	{ date: new Date('2021-07-29 00:00:00'), name: 'Día de la Independencia' },
	{ date: new Date('2021-08-30 00:00:00'), name: 'Día de Santa Rosa de Lima' },
	{ date: new Date('2021-10-08 00:00:00'), name: 'Combate de Angamos' },
	{ date: new Date('2021-11-01 00:00:00'), name: 'Todos los Santos' },
	{ date: new Date('2021-12-08 00:00:00'), name: 'La inmaculada concepción' },
	{ date: new Date('2021-12-25 00:00:00'), name: 'Navidad' },
	// 2022
	{ date: new Date('2022-01-01 00:00:00'), name: 'Año Nuevo' },
	{ date: new Date('2022-04-14 00:00:00'), name: 'Jueves Santo' },
	{ date: new Date('2022-04-15 00:00:00'), name: 'Viernes Santo' },
	{ date: new Date('2022-04-17 00:00:00'), name: 'Pascua' },
	{ date: new Date('2022-05-01 00:00:00'), name: 'Fiesta del trabajo' },
	{ date: new Date('2022-06-29 00:00:00'), name: 'San Pedro y San Pablo' },
	{ date: new Date('2022-07-28 00:00:00'), name: 'Día de la Independencia' },
	{ date: new Date('2022-07-29 00:00:00'), name: 'Día de la Independencia' },
	{ date: new Date('2022-08-30 00:00:00'), name: 'Día de Santa Rosa de Lima' },
	{ date: new Date('2022-10-08 00:00:00'), name: 'Combate de Angamos' },
	{ date: new Date('2022-11-01 00:00:00'), name: 'Todos los Santos' },
	{ date: new Date('2022-12-08 00:00:00'), name: 'La inmaculada concepción' },
	{ date: new Date('2022-12-25 00:00:00'), name: 'Navidad' },
];

export interface ConfigIncidents {
	scaRoutingIncidenciaCustomDto: ScaRoutingIncidenciaCustomDto;
	scaIncidenciaConfiguracionCustomDto: ScaIncidenciaConfiguracionCustomDto;
	scaRoutingProjection: ScaRoutingProjection;
}

interface DayOptions {
	days: number;
	businessDays: boolean;
	addDate: boolean;
}

@Injectable({ providedIn: 'root' })
export class ScaRoutingIncidenciaService extends GenericService<ScaRoutingIncidencia, number> {
	private readonly _url = `${environment.HOST_CARGA}/scaroutingincidencias`;

	previusIncidencia$ = new Subject<ScaRoutingIncidenciaListadoProjection | null>();

	private readonly _dateUtil = new DateUtil();

	constructor(protected readonly _http: HttpClient, protected readonly _scaIncidenciaConfiguracionService: ScaIncidenciaConfiguracionService) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllCustomDto(search: ScaRoutingIncidenciaCustomDto): Observable<ScaRoutingIncidenciaListadoProjection[]> {
		return this._http.post<ApiResponse<ScaRoutingIncidenciaListadoProjection[]>>(`${this._url}/model-se-ro/findAllCustomDto/`, search).pipe(
			map((res) => res.data),
			map((incidencias) => orderBy(incidencias, (i) => i.fchIncidencia, 'desc')),
			map((incidencias) =>
				incidencias.map((incidencia: ScaRoutingIncidenciaListadoProjection) => ({
					...incidencia,
				}))
			)
		);
	}

	findAllIncidencias(configIncidents: ConfigIncidents): Observable<ScaRoutingIncidenciaListadoProjection[]> {
		const { scaRoutingIncidenciaCustomDto, scaIncidenciaConfiguracionCustomDto, scaRoutingProjection: scaRoutingListadoProjection } = configIncidents;

		const { idRouting, idTipoEnvio, idNaturalezas } = scaRoutingListadoProjection;

		return forkJoin([
			this.findAllCustomDto(scaRoutingIncidenciaCustomDto),
			this._findAllIncidenciaConfiguraciones(scaIncidenciaConfiguracionCustomDto, {
				idTipoEnvio,
				idNaturalezas: idNaturalezas ?? [],
			}),
		]).pipe(
			map(([routingInicidencias, incidenciaConfiguraciones]) => {
				const idIncidencias = [...new Set(routingInicidencias.map((ri) => ri.idIncidencia))];

				const newRoutingInicidencias = routingInicidencias.map((ri) => {
					// Calcular fecha de vencimiento

					const addDate = ri.tipoCalculoPlazo === EnumTipoCalculoPlazo.D ? true : false;

					let fchVencimiento: Date | null = null;

					if (ri.tipoFechaCalculo) {
						const date = this._getTipoFecha(ri.tipoFechaCalculo, scaRoutingListadoProjection);

						if (date !== null) {
							fchVencimiento = this._getFechaVencimiento(new Date(date), {
								days: ri.diasPlazo ?? 0,
								businessDays: ri.ctrlDiaHabil ?? false,
								addDate,
							});
						}
					}

					return {
						...ri,
						fchVencimiento,
						idRouting,
					} as ScaRoutingIncidenciaListadoProjection;
				});

				// Se guarda el ultimo registro del routingInicidencias
				if (newRoutingInicidencias[newRoutingInicidencias.length - 1]) this.previusIncidencia$.next(newRoutingInicidencias[newRoutingInicidencias.length - 1]);
				else this.previusIncidencia$.next(null);

				const newIncidenciaConfiguraciones = incidenciaConfiguraciones
					.filter((ic) => !idIncidencias.includes(ic.idIncidencia))
					.map(
						(ic) =>
							({
								idRoutingIncidencia: null,
								idRouting,
								observacion: null,
								fchIncidencia: null,
								fchVencimiento: null,
								idIncidencia: ic.idIncidencia,
								nombreIncidencia: ic.nombre,
								idUsuario: null,
								usuario: null,
								idGrupoIncidencia: ic.idGrupoIncidencia,
								nombreGrupoIncidencia: ic.nombreGrupoIncidencia,
							} as ScaRoutingIncidenciaListadoProjection)
					);

				return [...newRoutingInicidencias, ...newIncidenciaConfiguraciones];
			})
		);
	}

	private _findAllIncidenciaConfiguraciones(
		scaIncidenciaConfiguracionCustomDto: ScaIncidenciaConfiguracionCustomDto,
		{ idTipoEnvio, idNaturalezas }: { idTipoEnvio: number; idNaturalezas: number[] }
	): Observable<ScaIncidenciaConfiguracion[]> {
		return this._scaIncidenciaConfiguracionService.findAllCustomDto(scaIncidenciaConfiguracionCustomDto).pipe(
			map((incidenciaConfiguraciones) => {
				const d1 = incidenciaConfiguraciones.filter((incidenciaConfiguracion) => incidenciaConfiguracion.idTipoEnvio === null && incidenciaConfiguracion.idNaturalezaCarga === null);

				const d2 = incidenciaConfiguraciones.filter(
					(incidenciaConfiguracion) => incidenciaConfiguracion.idTipoEnvio === idTipoEnvio && [...new Set(idNaturalezas)].includes(incidenciaConfiguracion?.idNaturalezaCarga ?? -1)
				);

				return [...d1, ...d2];
			})
		);
	}

	private _getTipoFecha(tipoFechaCalculo: EnumTipoFecha, scaRoutingProjection: ScaRoutingProjection): string | null {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { fchRO, fchETA, fchATA, fchETD, fchATD } = scaRoutingProjection;

		let fchVencimiento: string | null = null;

		switch (tipoFechaCalculo) {
			case EnumTipoFecha.ROU.code:
				if (fchRO) fchVencimiento = fchRO;
				break;
			case EnumTipoFecha.ETA.code:
				if (fchETA) fchVencimiento = fchETA;
				break;
			case EnumTipoFecha.ATA.code:
				if (fchATA) fchVencimiento = fchATA;
				break;
			case EnumTipoFecha.ETD.code:
				if (fchETD) fchVencimiento = fchETD;
				break;
			case EnumTipoFecha.ATD.code:
				if (fchATD) fchVencimiento = fchATD;
				break;
		}

		return fchVencimiento ? fchVencimiento.toString() : null;
	}

	private _isHoliday(date: Date): boolean {
		return HOLIDAYS_TEMP.map((h) => this._dateUtil.formatDate(h.date, DateFormat.DATE)).includes(this._dateUtil.formatDate(date, DateFormat.DATE));
	}

	private _isHolidayPrueba(date: Date, addDate: boolean): Date {
		let newDate = date;

		const isHoliday = this._isHoliday(date);

		if (!isHoliday) return newDate;

		newDate = addDate ? this._dateUtil.addDay(newDate, 1) : this._dateUtil.subtractDay(newDate, 1);

		return this._isHolidayPrueba(newDate, addDate);
	}

	private _getFechaVencimiento(currentDate: Date, { days, businessDays, addDate }: DayOptions) {
		let newDate = currentDate;
		const addAndSubtractDay = (day: number) => (addDate ? this._dateUtil.addDay(newDate, day) : this._dateUtil.subtractDay(newDate, day));
		const processNonBusinessDay = () => {
			newDate = this._isHoliday(newDate) ? addAndSubtractDay(2) : addAndSubtractDay(1);
		};

		if (businessDays) {
			for (let i = 1; i <= days; i++) {
				if (newDate.getDay() === 0) {
					addAndSubtractDay(1);
				}
				processNonBusinessDay();
			}

			if (newDate.getDay() === 0) {
				addAndSubtractDay(1);
			}
		} else {
			addAndSubtractDay(days);
		}
		return newDate;
	}
}
