import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ApiResponse } from '@utils/models/ApiResponse';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ScaOrdenManifiestoDtoSearch } from '~models/carga/dto';
import { ScaOrdenManifiestoProjection } from '~models/carga/projection/ScaOrdenManifiestoProjection';
import { ConfigService } from '@utils/interface/service.interface';
import { GenericService } from '@utils/classes/GenericService';
import { ScaOrdenManifiesto } from '~models/carga/interface/ScaOrdenManifiesto';
import { SeeEnvio } from '~models/emision-electronica/dto/SeeEnvio';
import { ScaOrdenManifiestoResponseDtoCustom } from '~models/carga/dto/ScaOrdenManifiestoResponseDtoCustom';
import { ScaTransmisionXmlDtoSearch } from '~models/carga/search/ScaOrdenManifiestoDtoSearch';

@Injectable({
	providedIn: 'root',
})
export class ScaOrdenManifiestoService extends GenericService<ScaOrdenManifiesto, number> {
	private readonly _url = `${environment.HOST_CARGA}/scaordenmanifiestos`;

	constructor(private http: HttpClient) {
		super(http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByCustomDto(searchMnifiestoDto: ScaOrdenManifiestoDtoSearch): Observable<ScaOrdenManifiestoProjection[]> {
		return this.http.post<ApiResponse<ScaOrdenManifiestoProjection[]>>(`${this._url}/model-se-ro/findAllBySearch`, searchMnifiestoDto).pipe(map((res) => res.data));
	}

	findByIdListResumenManifiesto(id: number): Observable<any> {
		return this.http.get<ApiResponse<any>>(`${this._url}/model-se-ro/findByIdListResumenManifiesto/${id}`).pipe(map((res) => res.data));
	}

	/*generateScaOrdenManifiestoXml(search: ScaTransmisionXmlDtoSearch): Observable<ScaOrdenManifiestoResponseDtoCustom> {
		return this.http.post<ApiResponse<ScaOrdenManifiestoResponseDtoCustom>>(`${this._url}/generateScaOrzdenManifiestoXml`, search).pipe(map((res) => res.data));
	}*/

	generateAllScaOrdenManifiestoXml(listSearch: ScaTransmisionXmlDtoSearch[]): Observable<ScaOrdenManifiestoResponseDtoCustom[]> {
		return this.http.post<ApiResponse<ScaOrdenManifiestoResponseDtoCustom[]>>(`${this._url}/generateAllScaOrdenManifiestoXml`, listSearch).pipe(map((res) => res.data));
	}
	updateScaOrdenManifiestoAfterAcuse(update: SeeEnvio): Observable<ScaOrdenManifiesto> {
		return this.http.post<ApiResponse<ScaOrdenManifiesto>>(`${this._url}/updateScaOrdenManifiestoAfterAcuse`, update).pipe(map((res) => res.data));
	}
}
